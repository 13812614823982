<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                <h1 class="titulo-pagina">Hola, nos alegra saber de ti.</h1>               
            </div>
        </div>
    </div>
</section>
<div class="container gracias">
    <div class="row">
        <div class="col-md-6 offset-md-3 pt-3 pb-5 text-center">
            <img src="assets/images/success-icon.png" width="80" class="mb-3">
            <p>Tu <strong>mensaje</strong> nos ha llegado y estamos muy entusiasmados por poder iniciar una nueva conversación contigo. Cómo sabes, la paciencia es una cualidad que queremos cultivar, por lo que en cuanto podamos, te daremos una respuesta. Se paciente, y en breve platicaremos un poco más.</p>
            <br>
            <h3>¡Gracias por ser parte de este movimiento!</h3>
            <br>
            <p>Te invitamos a seguir navegando por nuestro sitio y descubrir información que te cambiará la vida.</p>
        </div>
    </div>
</div>
<section class="libros-sugeridos">
    <div class="container pb-5 pt-5">
    <div class="row">
        <div class="col-lg-4">
            <img class="flor mb-3" src="http://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo">
                Libros que cambiarán tu vida
            </p>
            <p>¿Alguna vez has leído un libro que te impactó tanto como para cambiar tu vida? ¿Uno que ha hecho que
                desde entonces todo sea diferente?<br><br>
                Puede sonar exagerado, pero lo cierto es que existen algunos libros que por su alto valor y utilidad
                para los lectores, ayudan de verdad a ver el mundo de forma diferente.</p>
            <a routerLink="/libros" class="btn btn-uno mb-5"> Más libros</a>
        </div>
        <!-- LIBROS SUGERIDOS -->
        <div  *ngFor="let libro of libros.slice(0, 4);" class="col-lg-2 col-sm-3 col-6">
            <a class="libro-sugerido" (click)="getLibroUrl(libro)" style="cursor:pointer">
                <img class="libro1" src="http://mts.org.mx/assets/images/libros/{{ libro?.thumbnail }}">
                <p class="nombre-libro1">{{ libro.titulo }}</p>
                <img class="rating" src="http://mts.org.mx/assets/images/rating/{{ libro?.rating }}.svg">
            </a>
        </div>
        <!--FIN LIBROS SUGERIDOS -->
    </div>
</div>
</section>