import { Component, OnInit} from '@angular/core';
import { LibrosService} from '../../services/libros.service';
import { MtsService} from '../../services/mts.service';
import { ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';

@Component({
    selector: 'app-libro',
    templateUrl: './libro.component.html',
    styleUrls: ['./libro.component.css']
})
export class LibroComponent implements OnInit {
    libro: any
    libros: any[]
    ComentarioLib: any
    ComentariosLib: any[]
    idnotshow: any
    newstr: any
    max = 5;
    rate = 0;
    isReadonly = false;
    dataStorgae: any;
    Nombre: string
    Comentario: string
    totalcomentarios: any
    path: string;

    constructor(
        private router: Router,
        private librosService: LibrosService,
        private activatedRoute: ActivatedRoute,
        public environment: Environment,
        private toastr: ToastrService,
        private mtsService : MtsService,
        private _urlcanonicaservice: UrlcanonicaService,
        public meta: Meta, 
        public title: Title
    ) {  
        this.path = this.activatedRoute.snapshot['_routerState'].url;
        this.environment.pathActual=this.path;
        const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Libros que cambiaran tu vida │ MTS");
      meta.updateTag({
        name: "description",
        content: "¿Alguna vez has leído un libro que te impactó tanto como para cambiar tu vida? ¿Uno que ha hecho que desde entonces todo sea diferente?"
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
      
       
    }
    ngOnInit() {
        this.activatedRoute.params.subscribe(param => {
            this.librosService.getLibros().subscribe((resp: any) => {
                this.idnotshow = param.id
                this.libro = resp.find((libro: any) => libro.id === parseInt(param.id))
                this.mtsService.votos(this.libro.idTipo).subscribe((res: any)=> {
                    this.libro.rating = Math.round(res * 10) / 10;
                    this.dataStorgae = JSON.parse(localStorage.getItem(this.libro.idTipo));
                    this.getComentarios(this.libro.idTipo);
                    if(this.dataStorgae == true){
                        this.isReadonly = true;
                    }else{
                        this.isReadonly = false;
                    }
                   
                })
            })
            
            this.librosService.getLibros().subscribe((respdos: any) => {
                respdos.reverse()
                var removeIndex = respdos.map(function(item) {
                    return item.id;
                }).indexOf(parseInt(this.idnotshow));
                respdos.splice(removeIndex, 1);

                respdos.forEach((value, index) => {
                    if(index <= 5){
                        this.mtsService.votos(value.idTipo).subscribe((res: any)=> {
                            value.rating = Math.round(res * 10) / 10;;
                        })
                    } 
                });
                this.libros = respdos.slice(0, 6);
            })
        }) 
    }


    confirmSelection() {
        if(this.dataStorgae == true){
            console.log("Nada ya boto anteriormente que haremos ? ")
        }else{
            const params = {
                "idTipo" : this.libro.idTipo,
                "Tipo" : this.libro.Tipo,
                "votos" : this.rate
            }
            this.mtsService.califica(params).subscribe(
                () => {
                    localStorage.setItem(this.libro.idTipo, "true");
                    this.toastr.success('Voto registrado correctamente');
                    setTimeout(function () {
                        this.ngOnInit()
                      }, 2000);
                },
                err => {
                console.log(err)
                }
            );
        }
    }


    sendComentario(f: NgForm){
        console.log(this.libro)
        f.value.Megusta = 0;
        f.value.NoMegusta = 0;
        f.value.Idtipo = this.libro.idTipo;
        f.value.Tipo = this.libro.Tipo;

        this.mtsService.postComentario(f.value).subscribe(
            resp => {
                f.reset();
                this.toastr.success('Comentario agregado correctamente');
                this.getComentarios(this.libro.idTipo);
            },
            err => {
            console.log(err)
            }
        );
    }

    

    like(comentario: any){
        this.mtsService.postComentarioVoto(comentario.id, true).subscribe(
            resp => {
                console.log(resp)
                this.getComentarios(this.libro.idTipo);
                //aqui ya realizo voto al comentario ¿ Que haremos ?
            },
            err => {
            console.log(err)
            }
        );
    }

    dislike(comentario: any){
        this.mtsService.postComentarioVoto(comentario.id, false).subscribe(
            resp => {
                console.log(resp)
                this.getComentarios(this.libro.idTipo);
                //aqui ya realizo voto al comentario ¿ Que haremos ?
            },
            err => {
            console.log(err)
            }
        );
    }

    getComentarios(id){
        this.mtsService.getComentarios(id).subscribe((res: any)=> {
            this.ComentariosLib = res;
            this.totalcomentarios = this.ComentariosLib.length;
        })
    }

    getLibroUrl(libro: any) {
        this.newstr = libro.titulo.replace(/\s+/g, '-').toLowerCase();
        let reemplazarAcentos = function(cadena) {
            let chars = {
                "á": "a",  ":": "",  ";": "", "é": "e", "í": "i", "ó": "o", "ú": "u", "!": "", "à": "a",
                "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n", ".": "", "¡": "", "Á": "A", "É": "E", "Í": "I",
                "Ó": "O", "Ú": "U", ",": "", "¿": "", "?": "", "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"}
            let expr = /[áàéèíìóòúùñ:;,¿?¡!]/ig;
            let res = cadena.replace(expr, function(e) {
                return chars[e]
            });
            return res;
        }
        this.newstr = reemplazarAcentos(this.newstr);
        this.router.navigate(['/libro/' + libro.id + '/' + this.newstr]);
        this.getLibro();
    }

    getLibro() {
        this.activatedRoute.params.subscribe(param => {
            this.librosService.getLibros().subscribe((resp: any) => {
                this.idnotshow = param.id
                this.libro = resp.find((libro: any) => libro.id === parseInt(param.id))
            })
            this.librosService.getLibros().subscribe(() => {})
        })
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    redirectTo(uri:string){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
     }
}