import { Component, OnInit } from '@angular/core';
import { EventosService } from '../../services/eventos.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';
import { MtsService } from '../../services/mts.service';
import { NgForm } from '@angular/forms';
import {AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.css']
})
export class EventoComponent implements OnInit {
  evento: any
  idnotshow: any
  newstr: any;
  path: string;
  msjenvio: boolean = false;
  msjexiste: boolean = false;
  msjerro: boolean = false;
  msjsucces:boolean = false;
  
  Nombre: string
  Email: string
  Telefono: string
  Estado: string

  submitted = false;
  emailPattern = "^[^ ]+@[^ ]+\.[a-z]{2,63}$";


  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];


  EventoForm: FormGroup = new FormGroup({
    phone: new FormControl(undefined),
    Estado: new FormControl(''),
    Nombre: new FormControl(''),
    Email: new FormControl(''),
    acceptTerms: new FormControl(false),
  });
  

  constructor(
    private router: Router,
    private eventosService: EventosService,
    private activatedRoute: ActivatedRoute,
    public environment: Environment,
    private formBuilder: FormBuilder,
    private mtsService : MtsService,
    private _urlcanonicaservice: UrlcanonicaService,
    public meta: Meta, 
    public title: Title

  ) {
    this.path = this.activatedRoute.snapshot['_routerState'].url;
    this.environment.pathActual=this.path;
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Evento │ MTS");
      meta.updateTag({
        name: "description",
        content: "Estudios demuestran que meditar aumenta las emociones positivas y el nivel de satisfacción con nuestra vida, a la vez que disminuye el estrés, la ansiedad y la depresión."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
   }

   ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.eventosService.getEventos().subscribe((resp: any) =>{
        this.idnotshow = param.id
        this.evento = resp.find((evento: any) => evento.id === parseInt(param.id))
      } )
      
    })

    this.EventoForm = this.formBuilder.group({
      Nombre: ['', 
        [
          Validators.required, 
          Validators.minLength(3), 
        ],
      ],
      Email: ['', 
        [
          Validators.required, 
          Validators.pattern(this.emailPattern)
        ],
      ],
      Estado: ['',
         Validators.required
      ],
      phone: [undefined, 
        Validators.required
      ],
      acceptTerms: [false, 
        Validators.requiredTrue
      ],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.EventoForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.EventoForm.invalid) {
      return;
    }else{
      this.addEvento();
    }

    
  }

  onReset(): void {
    this.submitted = false;
    this.EventoForm.reset();
  }

  convert(str: string | number | Date) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  addEvento(){
    this.msjenvio = true;
    var formValue = this.EventoForm.value;
    var params = {
      Nombre: formValue.Nombre,
      Email: formValue.Email,
      Estado: formValue.Estado,
      Lada: formValue.phone.dialCode,
      Telefono: formValue.phone.number.replace(/ /g, "")
    };
    

    this.eventosService.postContacto(params).subscribe((resp: any) => {
          if(resp.ok == false){
            this.msjexiste = true;
            this.msjenvio = false;
            setTimeout(()=>{                           
              this.msjexiste = false;
            }, 5000);
          }else{
            setTimeout(()=>{                           
              this.msjenvio = false;
              this.submitted = false;
              this.EventoForm.reset();
              this.router.navigate(['gracias-evento/']);
            }, 5000);
          }
        },
        err => {
          this.submitted = false;
          this.EventoForm.reset();
          this.msjerro = true;
          this.msjenvio = false;
          setTimeout(()=>{                           
            this.msjerro = false;
           }, 5000);
        }
      );
    }

  
  getEvento(id: number){
    this.activatedRoute.params.subscribe(param => {
      this.eventosService.getEventos().subscribe((resp: any) => {
        this.idnotshow = param.id
        this.evento = resp.find((evento: any) => evento.id === parseInt(param.id))
      })
      this.eventosService.getEventos().subscribe((respdos: any) => {
      })
    })
  }

}
