import { Component, OnInit, ViewChild } from '@angular/core';
import { MeditacionesService } from "../../services/meditaciones.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalService, BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { Environment } from '../../Globals';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-meditaciones-guiadas',
  templateUrl: './meditaciones-guiadas.component.html',
  styleUrls: ['./meditaciones-guiadas.component.css']
})
export class MeditacionesGuiadasComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('lgModal') template: ModalDirective
  isModalShown = false
  meditaciones = [];
  videoModal: any;
  idnotshow: any
  scrollint: any
  scrollint2:any
  path: string;


  constructor(
    private modalService: BsModalService,
    private meditacionesService: MeditacionesService,
    public environment: Environment,
    private activeroute: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {
    this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
    this.scrollint = 0;
    this.scrollint2 = 0;
    this.meditacionesService.getMeditaciones().subscribe((resp: any) => {
      this.meditaciones = resp;  
      resp.reverse()
    });


   }
   getVideoModal(video){
    console.log(video.youtubeLink);
   
    this.videoModal = video.youtubeLink;
    this.openModal();
    //getVideoModal
  };

  getVideoIframe(url) {
    if(this.scrollint === 1){
      
    }else{
      var video, results;
 
      if (url === null) {
          return '';
      }
      results = url.match('[\\?&]v=([^&#]*)');
      video   = (results === null) ? url : results[1];
      this.scrollint = 1;
      return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?loop=1&amp;playlist=' + video);   
      
    }  
  }

  getVideoIframes(url) {
    if(this.scrollint2 === 1){
      
    }else{
      var video, results;
 
      if (url === null) {
          return '';
      }
      results = url.match('[\\?&]v=([^&#]*)');
      video   = (results === null) ? url : results[1];
      this.scrollint2 = 1;
      return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?loop=1&amp;playlist=' + video);   
      
    }  
  }

  openModal() {

    this.isModalShown = true
  }
  
  //oculta modal
  onHidden(): void {
    this.scrollint = 0;
    this.isModalShown = false
  }

  ngOnInit() {
  }

}
