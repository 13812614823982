import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DestinosService {
  constructor(private http: HttpClient) {}
  
  getDestinos() {
    return this.http.get("assets/data/destinos.json");
  }
}
