import { Component, HostListener, OnInit } from '@angular/core';
import { Environment } from './Globals';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  deviceInfo = null;

  constructor(public environment: Environment,private deviceService: DeviceDetectorService) {  
    this.epicFunction();
    this.environment.scroll=0;
    console.log(this.environment.play)
  }

  // @HostListener("document:scroll", []) onWindowScroll() {
  //   if(this.environment.scroll === 1){
  //     console.log("ya reproducion")
  //   }else{
  //     this.playMTS();
      
  //   }   
  // }
  
  // @HostListener('touchmove', ['$event']) handleTouch(){ 
  //   if(this.environment.scroll === 1){
  //     console.log("ya reproducion")
  //   }else{
  //     this.playMTS();
  //   }
  // }
    
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if(isMobile == true || isTablet == true){
      this.environment.mobile = true;
    } else {
      this.environment.mobile = false;
    }
  }

  mutesimi() {
    if (this.environment.sound != null) {
      if (this.environment.sound.paused) {
      } else {
        if (this.environment.sound.muted === true) {
          this.environment.sound.muted = false;
          document.getElementById('icomut').setAttribute('src', 'assets/images/play.png');
         
        } else if (this.environment.sound.muted === false) {
          this.environment.sound.muted = true;
          document.getElementById('icomut').setAttribute('src', 'assets/images/mute.png');
        }
      }
    }
  }


  createsimi() {
    const audio = 'https://mts.org.mx/assets/audio/background.mp3';
    this.environment.sound.src = audio;
    this.environment.sound.load();
    this.environment.sound.play();
    //this.environment.sound.loop(true);
    this.environment.sound = this.environment.sound;
    if (this.environment.sound.muted == true) {
      this.environment.sound.muted = false;
    }
    this.environment.play = true;   
    this.environment.scroll = 1; 
    this.environment.sound.onended = function(){
      const audio = 'https://mts.org.mx/assets/audio/background.mp3';
      this.src = audio;
      this.load();
      this.play();
      
    };
  }


  playMTS() {
    if (this.environment.sound == null) {
      this.environment.sound = new Audio();
      this.createsimi();
    } else {
      if (this.environment.sound.src == 'https://mts.org.mx/assets/audio/background.mp3') {
        if (this.environment.sound.play) {
          if (this.environment.sound.pause) {
            if (this.environment.sound.muted == true) {
              this.environment.sound.muted = false;
            }
            this.environment.sound.play();
            this.environment.play = true;
           
          }
        }
      }
    }
  }
 
  ngOnInit() {
      
  }
}
