import { Injectable } from '@angular/core';

@Injectable()
export class Environment {
    sound = null;
    roco = false;
    play = false;
    silent = false;
    rocopausa = false;
    simipausa = false;
    navbarfixed = false;
    home = false;
    mobile = false; 
    imgsimi = false;
    imgroco = false;
    scroll = null;
    pathActual = null;
}
