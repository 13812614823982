<section class="section-titulo ">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                <h1 class="titulo-pagina">Frases</h1>
                <p class="titulo-descripcion">
                    Todas necesitamos frases motivacionales de vez en cuando. Sobre todo en esos días en que nos parece todo un poco más duro.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="row">
        <div class="card-columns" >

          <div *ngFor="let frase of frases" class="mb-3 card ">
            <img class="img-fluid" src="assets/images/frases/{{ frase?.imagen }}" alt="{{ frase?.alt }}">
          </div>
                     
         </div>
    </div>
</div>