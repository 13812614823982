<app-header></app-header>

<router-outlet></router-outlet>

<app-footer></app-footer>


<div class="boton-play" *ngIf="environment.play === false">
    <div *ngIf="environment.mobile === false">
        <img src="assets/images/but-play.png" alt="play MTS" style="cursor: pointer;" (click)="playMTS();"/>
    </div>
    <div  *ngIf="environment.mobile === true">
        <img src="assets/images/but-play.png" alt="play MTS" style="cursor: pointer;" (touchend)="playMTS();"/>
    </div>
</div>

<div class="boton-mute" *ngIf="environment.play === true" >
    <div *ngIf="environment.mobile === false">
        <img  src="assets/images/play.png" alt="mute MTS" style="cursor: pointer;" id="icomut" class="icorep" (click)="mutesimi();"/>
    </div>
    <div  *ngIf="environment.mobile === true">
        <img  src="assets/images/play.png" alt="mute MTS" style="cursor: pointer;" id="icomut" class="icorep" (touchend)="mutesimi();"/>
    </div>
</div>

