<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Meditaciones Guiadas</h1>
                <p class="titulo-descripcion">
                    Si estás empezando con la práctica de la meditación, escuchar una meditación guiada puede serte de gran ayuda.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container my-5 ">
    <div class="row pt-5">
        <div class="col-md-5 pt-4">
            
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <h1 class="titulo-xl mb-3">¿Qué es la meditación guiada?</h1>
            <p>
                La meditación guiada es una herramienta que permite, mediante palabras e imágenes, dejar de lado emociones 
                negativas como el dolor, el estrés y las preocupaciones del día a día, ayudándonos a conectar con nuestro 
                interior.<br><br>La forma en que se realiza la meditación guiada es bastante 
                simple. Una persona que actúa de experta en meditación, como un gurú o un guía espiritual, se encarga de 
                ofrecer una serie de instrucciones para ayudar a las personas interesadas alcanzar un estado de relajación.
            </p>
        </div>
        <div class="col-md-7 ">
            
            <div *ngFor="let meditacion of meditaciones.slice(0, 1);">
                <iframe class="video" [src]="getVideoIframes(meditacion?.youtubeLink)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <p class="video-titulo-principal">{{ meditacion.titulo }}</p>
            </div>
           
           
        </div>
        <div class="col-12 mt-5">
            <h3>Otros videos</h3>
        </div>
        <div  *ngFor="let meditacion of meditaciones" class="col-lg-4 col-sm-6 meditaciones">
            <a (click)="getVideoModal(meditacion)" class="video-cont">
                <div class="video-imagen" [ngStyle]="{background: 'url(assets/images/meditaciones/' + meditacion?.thumbnail + ')'}">
                </div>
                <div class="video-descripcion">
                    <div class="video-texto">
                        <p class="video-titulo">
                            {{ meditacion.titulo }}
                        </p>
                    </div>
                </div>
            </a>
        </div>

        <div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
            <div class="modal-dialog modal-video">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close pull-right" aria-label="Close" (click)="lgModal.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <iframe class="video-frame"  [src]="getVideoIframe(videoModal)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
        
        
       
    </div>
</div>
