import { Component, OnInit } from '@angular/core';
import { TecnicasService } from '../../services/tecnicas.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';


@Component({
  selector: 'app-tecnicas-de-meditacion',
  templateUrl: './tecnicas-de-meditacion.component.html',
  styleUrls: ['./tecnicas-de-meditacion.component.css']
})
export class TecnicasDeMeditacionComponent implements OnInit {
  tecnicas = [];
  path: string;

  constructor(
    private tecnicasService: TecnicasService,
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private activatedRoute: ActivatedRoute,
    public environment: Environment,
    public meta: Meta, 
    public title: Title
  ) {
    this.path = this.activatedRoute.snapshot['_routerState'].url;
    this.environment.pathActual=this.path;
    this.tecnicasService.getTecnicas().subscribe((resp: any) => {
      this.tecnicas = resp;
      resp.reverse()
    } );
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Técnicas de meditación │ MTS");
      meta.updateTag({
        name: "description",
        content: "Estudios demuestran que meditar aumenta las emociones positivas y el nivel de satisfacción con nuestra vida, a la vez que disminuye el estrés, la ansiedad y la depresión."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
   }
  newstr: any; 

  ngOnInit() {
  }

  getTecnicaUrl(tecnica: any) {
    this.newstr = tecnica.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos = function(cadena) {
      let chars={
        "á":"a", ":":"",  ";":"", "é":"e", "í":"i", "ó":"o", "ú":"u", "!":"",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n", ".":"", "¡":"",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U", ",":"", "¿":"", "?":"",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"};
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(["/meditacion/" + tecnica.id + "/" + this.newstr]);
  }

}
