import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor(private http: HttpClient ) {}
  getEventos(){
    return this.http.get("assets/data/eventos.json")
  }

  postContacto(comentario: any) {
    return this.http.post(`https://mts.org.mx/WebApi/MTS/api/dinamica/`, comentario, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
}


