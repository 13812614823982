import { Component, OnInit } from '@angular/core';
import { GurusService} from "../../services/gurus.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';


@Component({
  selector: 'app-gurus',
  templateUrl: './gurus.component.html',
  styleUrls: ['./gurus.component.css']
})
export class GurusComponent implements OnInit {
  gurus = [];
  path: string;

  constructor(
    private gurusService: GurusService,
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private activeroute: ActivatedRoute,
    public environment: Environment,
    public meta: Meta, 
    public title: Title
  ) {
    this.gurusService.getGurus().subscribe((resp: any) => {
      this.gurus = resp;
      resp.reverse()
    });
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical === null) {
      this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
    }else{
      canonical.setAttribute('href', 'https://mts.org.mx/');
    }

    title.setTitle("Gurus que cambiaran tu vida │ MTS");
    meta.updateTag({
      name: "description",
      content: "Un verdadero Gurú es como el océano, está disponible para lo que se necesite. Depende de cada uno de nosotros si lo aprovechamos para mirarlo desde lejos, mojarnos los pies o sumergirnos en las profundidades y encontrar grandes tesoros."
    });
    meta.updateTag({
      name: "keywords",
      content:
        "Movimiento Trascendental Social"
    });       
    this.path = this.activeroute.snapshot['_routerState'].url;
    this.environment.pathActual=this.path;
   }

  newstr: any;

  ngOnInit() {
  }

  getGuruUrl(guru: any) {
    this.newstr = guru.nombre.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos = function(cadena) {
      let chars = {
        á: "a",
        ":": "",
        ";": "",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        "!": "",
        à: "a",
        è: "e",
        ì: "i",
        ò: "o",
        ù: "u",
        ñ: "n",
        ".": "",
        "¡": "",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
        ",": "",
        "¿": "",
        "?": "",
        À: "A",
        È: "E",
        Ì: "I",
        Ò: "O",
        Ù: "U",
        Ñ: "N"
      };
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(["/guru/" + guru.id + "/" + this.newstr]);
  }

}
