import { Component, OnInit } from '@angular/core';
import { GurusService } from '../../services/gurus.service';
import { ActivatedRoute } from '@angular/router'; 
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MtsService} from '../../services/mts.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RatingModule } from 'ngx-bootstrap';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from '../../Globals';

@Component({
  selector: 'app-guru',
  templateUrl: './guru.component.html',
  styleUrls: ['./guru.component.css']
})
export class GuruComponent implements OnInit {
  guru:any;
  gurus:any [];
  idnotshow: any;
  newstr: any;
  max = 5;
  rate = 0;
  isReadonly = false;
  videoURL: string;
  safeURL: any;
  dataStorgae: any;
  ComentarioLib: any
  ComentariosLib: any[]
  totalcomentarios: any
  Nombre: string
  Comentario: number
  scrollint: any
  path: string;
  videoPrincipal: any;
  sendUrl: any;


  constructor(
    private router: Router,
    private gurusService: GurusService,
    private activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private mtsService : MtsService,
    public environment: Environment,
    private _urlcanonicaservice: UrlcanonicaService,
    public meta: Meta, 
    public title: Title
  ) {
    this.scrollint = 0;
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/guru/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/guru/');
      }
  
      title.setTitle("Gurus que cambiaran tu vida │ MTS");
      meta.updateTag({
        name: "description",
        content: "En el marco del hinduismo, gurú significa ‘maestro espiritual’. Desde hace muchos siglos este término se ha empleado en la India. "
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
      this.path = this.activatedRoute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.gurusService.getGurus().subscribe((resp: any) =>{
        this.idnotshow = param.id
        this.guru = resp.find((guru: any) => guru.id === parseInt(param.id))
        this.videoPrincipal = this.guru;
        // this.mtsService.votos(this.guru.idTipo).subscribe((res: any)=> {
        //   this.guru.rating = Math.round(res * 10) / 10;
        //   this.dataStorgae = JSON.parse(localStorage.getItem(this.guru.idTipo));
        //   this.getComentarios(this.guru.idTipo);
        //   if(this.dataStorgae == true){
        //       this.isReadonly = true;
        //   }else{
        //       this.isReadonly = false;
        //   }
         
        // })
      })
      this.gurusService.getGurus().subscribe((respdos: any) => {
        respdos.reverse()
        var removeIndex = respdos.map(function(item) { return item.id; }).indexOf(parseInt(this.idnotshow));
        respdos.splice(removeIndex, 1);
        this.gurus = respdos
      })
    })
  }

  confirmSelection() {
    if(this.dataStorgae == true){
        console.log("Nada ya boto anteriormente que haremos ? ")
    }else{
        const params = {
            "idTipo" : this.guru.idTipo,
            "Tipo" : this.guru.Tipo,
            "votos" : this.rate
        }
        this.mtsService.califica(params).subscribe(
            resp => {
                localStorage.setItem(this.guru.idTipo, "true")
                this.toastr.success('Voto registrado correctamente');
                setTimeout(function () {
                  this.ngOnInit()
                }, 2000);
            },
            err => {
            console.log(err)
            }
        );
    }
  }

  
  getVideoIframe(url?:undefined) {
    if(url != undefined){
      this.sendUrl = url;
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.sendUrl + '?loop=1&amp;playlist=' + this.sendUrl);  
  }

  ngAfterViewChecked(): void{
    if(this.videoPrincipal != undefined){
      this.getVideoIframe(this.videoPrincipal.youtubeLink);
    }
  }

  // getVideoIframe(url) {
  //   if(this.scrollint === 1){
      
  //   }else{
  //     var video, results;
 
  //     if (url === null) {
  //         return '';
  //     }
  //     results = url.match('[\\?&]v=([^&#]*)');
  //     video   = (results === null) ? url : results[1];
  //     this.scrollint = 1;
  //     return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?loop=1&amp;playlist=' + video);   
      
  //   }  
  // }

  
  getGuru(id: number){
   
    this.activatedRoute.params.subscribe(param => {
      this.gurusService.getGurus().subscribe((resp: any) => {
        this.idnotshow = param.id
        this.guru = resp.find((guru: any) => guru.id === parseInt(param.id))
        console.log(this.guru)
        this.videoPrincipal = this.guru;
        // this.mtsService.votos(this.guru.idTipo).subscribe((res: any)=> {
        //   this.guru.rating = Math.round(res * 10) / 10;
        //   this.dataStorgae = JSON.parse(localStorage.getItem(this.guru.idTipo));
        //   this.getComentarios(this.guru.idTipo);
        //   if(this.dataStorgae == true){
        //       this.isReadonly = true;
        //   }else{
        //       this.isReadonly = false;
        //   }
        //  })
      })
      this.gurusService.getGurus().subscribe((respdos: any) => {
      })
    })
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  sendComentario(f: NgForm){
    console.log(this.guru)
    f.value.Megusta = 0;
    f.value.NoMegusta = 0;
    f.value.Idtipo = this.guru.idTipo;
    f.value.Tipo = this.guru.Tipo;

    this.mtsService.postComentario(f.value).subscribe(
        resp => {
            f.reset();
            this.toastr.success('Comentario agregado correctamente');
            this.getComentarios(this.guru.idTipo);
        },
        err => {
        console.log(err)
        }
    );
}

    

  like(comentario: any){
    this.mtsService.postComentarioVoto(comentario.id, true).subscribe(
        resp => {
            console.log(resp)
            this.getComentarios(this.guru.idTipo);
            //aqui ya realizo voto al comentario ¿ Que haremos ?
        },
        err => {
        console.log(err)
        }
    );
  }

  dislike(comentario: any){
      this.mtsService.postComentarioVoto(comentario.id, false).subscribe(
          resp => {
              console.log(resp)
              this.getComentarios(this.guru.idTipo);
              //aqui ya realizo voto al comentario ¿ Que haremos ?
          },
          err => {
          console.log(err)
          }
      );
  }

  getComentarios(id){
      this.mtsService.getComentarios(id).subscribe((res: any)=> {
          this.ComentariosLib = res;
          this.totalcomentarios = this.ComentariosLib.length;
      })
  }

}
