<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Destinos mágicos</h1>
                <p class="titulo-descripcion">
                    ¿Te imaginas visitar lugares mágicos del mundo que tal vez ni sabías que existían? En este planeta
                    existen lugares mágicos por doquier.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="contenedor-destinos">
        <div class="destinos-descripcion">
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo-xl">Dejate asombrar </p>
            <div class="pleca"></div>
            <p>Algunos de ellos tienen una magia peculiar, gracias a leyendas literarias y los mitos históricos que
                giran a su alrededor. Por ello, estos destinos resultan interesantes para los turistas y curiosos en
                todo el mundo.<br><br>
                localidades que mantienen su arquitectura original, tradiciones, historia y cultura con diferentes
                niveles de desarrollo, en donde la actividad turística contribuye para elevar los niveles de bienestar,
                mantener, fortalecer y optimizar el aprovechamiento racional de los recursos y atractivos naturales.</p>
        </div>
        <div class="destinos-grid">
            <a *ngFor="let destino of destinos" class="destino" (click)="getDestinoUrl(destino)" style="cursor:pointer">
                <div class="destino-text">
                    <img width="20" src="https://mts.org.mx/assets/images/ico-point.svg">
                    <p class="destino-nombre"><strong>{{ destino.titulo }}, </strong> {{ destino?.lugar }}</p>
                    <small>Ver más</small>
                </div>
                <div class="destino-img" style="background-image: url(https://mts.org.mx/assets/images/destinos/{{destino?.thumbnail}});">
                    <div class="destino-img-overlay"></div>
                </div>
            </a>

        </div>
    </div>
</div>