import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { VideosService } from "../../services/videos.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalService, BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from '../../Globals';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-videos",
  templateUrl: "./videos.component.html",
  styleUrls: ["./videos.component.css"]
})
export class VideosComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('lgModal') template: ModalDirective
  isModalShown = false
  videos = [];
  videoModal: any;
  scrollint: any
  path: string;
  sendUrl: any;
  videoPrincipal: any;
  
  constructor(
    private modalService: BsModalService,
    private videoService: VideosService,
    private _sanitizer: DomSanitizer,
    public environment: Environment,
    private activatedRoute: ActivatedRoute,
    private _urlcanonicaservice: UrlcanonicaService,
    public meta: Meta, 
    public title: Title
    ) {
      this.path = this.activatedRoute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
      this.scrollint = 0;
      this.videoService.getVideos().subscribe((resp: any) => {
        this.videos = resp;  
        resp.reverse()
      });
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Videos para reflexionar │ MTS");
      meta.updateTag({
        name: "description",
        content: "Videos recomendados para pensar sobre la vida, las relaciones y el modo en el que las experimentamos."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      });
    }

  getVideoModal(video){
    // console.log(video.youtubeLink);
   
    this.videoModal = video.youtubeLink;
    this.openModal();
    //getVideoModal
  };

  getVideoIframe(url?:undefined) {
    if(url != undefined){
      this.sendUrl = url;
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.sendUrl + '?loop=1&amp;playlist=' + this.sendUrl);  
  }

  ngAfterViewChecked(): void{
    if(this.videoPrincipal != undefined){
      this.getVideoIframe(this.videoPrincipal.idVideo);
    }
  }

  // getVideoIframe(url) {
  //   if(this.scrollint === 1){
      
  //   }else{
  //     var video, results;
 
  //     if (url === null) {
  //         return '';
  //     }
  //     results = url.match('[\\?&]v=([^&#]*)');
  //     video   = (results === null) ? url : results[1];
  //     this.scrollint = 1;
  //     return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?loop=1&amp;playlist=' + video);   
      
  //   }  
  // }

  openModal() {
    this.isModalShown = true
  }
  
  //oculta modal
  onHidden(): void {
    this.scrollint = 0;
    this.isModalShown = false
  }

  ngOnInit() {}
}
