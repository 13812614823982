<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Contáctanos</h1>
                <p class="titulo-descripcion">
                    ¡Acércate, queremos saber de ti!
                </p>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container pt-5 pb-5 mb-5">
        <div class="row" style="position: relative;">
            <div class="imagen-contacto"></div>

            <div class="col-lg-6 col-md-8 offset-lg-6 offset-md-4">

                <form ngNativeValidate #form="ngForm" (ngSubmit)="sendContacto(form)" class="row form-container">
                    <div class="col-12">
                        <h2>Escríbenos</h2>
                    <p>Deja tus comentarios y compartenos tu experiencia</p>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="Nombre">Nombre</label>
                        <input [(ngModel)]="Nombre" name="Nombre" class="form-control" id="Nombre" type="text"  required>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="Correo">Email</label>
                        <input [(ngModel)]="Correo" name="Correo" class="form-control" id="Correo" type="email"  required>
                    </div>
                    <div class="form-group col-12">
                        <label for="Estado">Estado</label>
                        <select [(ngModel)]="Estado" name="Estado" class="form-control" id="Estado" required>
                            <option value="Aguascalientes">Aguascalientes</option>
                            <option value="Baja California">Baja California</option>
                            <option value="Baja California Sur">Baja California Sur</option>
                            <option value="Campeche">Campeche</option>
                            <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                            <option value="Colima">Colima</option>
                            <option value="Chiapas">Chiapas</option>
                            <option value="Chihuahua">Chihuahua</option>
                            <option value="Ciudad de México">Ciudad de México</option>
                            <option value="Durango">Durango</option>
                            <option value="Guanajuato">Guanajuato</option>
                            <option value="Guerrero">Guerrero</option>
                            <option value="Hidalgo">Hidalgo</option>
                            <option value="Jalisco">Jalisco</option>
                            <option value="México">México</option>
                            <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
                            <option value="Morelos">Morelos</option>
                            <option value="Nayarit">Nayarit</option>
                            <option value="Nuevo León">Nuevo León</option>
                            <option value="Oaxaca">Oaxaca</option>
                            <option value="Puebla">Puebla</option>
                            <option value="Querétaro">Querétaro</option>
                            <option value="Quintana Roo">Quintana Roo</option>
                            <option value="San Luis Potosí">San Luis Potosí</option>
                            <option value="Sinaloa">Sinaloa</option>
                            <option value="Sonora">Sonora</option>
                            <option value="Tabasco">Tabasco</option>
                            <option value="Tamaulipas">Tamaulipas</option>
                            <option value="Tlaxcala">Tlaxcala</option>
                            <option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</option>
                            <option value="Yucatán">Yucatán</option>
                            <option value="Zacatecas">Zacatecas</option>
                        </select>
                    </div>
                    <div class="form-group col-12">
                      <label for="Mensaje">Dejanos tu mensaje</label>
                      <textarea [(ngModel)]="Mensaje" rows="3" id="Mensaje" name="Mensaje" class="form-control" required></textarea>
                    </div>
                    <div class="form-check col-12">
                        <input type="checkbox" class="form-check-input" id="Check1" required>
                        <label class="form-check-label" for="Check1">Estoy de acuerdo con el <a routerLink="/aviso-de-privacidad" target="_blank" > Aviso de privacidad</a></label>
                    </div>
                    <div class="col-12 text-center">
                        <div class="msj-form msj-enviando" *ngIf="msjenvio">
                            <img src="assets/images/loading.gif">
                        </div>
                        <div class="msj-form msj-error" *ngIf="msjerro">
                            <img src="assets/images/error-icon.png"><br>Algo salió mal, vuelve a intentarlo más tarde 
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <button type="submit" class="btn btn-uno">Enviar</button>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</section>
