import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MeditacionesService {

  constructor(private http: HttpClient) {}

  getMeditaciones() {
    return this.http.get("assets/data/meditaciones.json");
  }
}
