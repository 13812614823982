import { Component, OnInit } from '@angular/core';
import { LibrosService } from "../../services/libros.service";
import { Router, ActivatedRoute } from "@angular/router";
import {MtsService} from '../../services/mts.service';
import {DomSanitizer} from "@angular/platform-browser";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';


@Component({
  selector: 'app-gracias-afiliate',
  templateUrl: './gracias-afiliate.component.html',
  styleUrls: ['./gracias-afiliate.component.css']
})
export class GraciasAfiliateComponent implements OnInit {
   libros = [];
   path: string;

  constructor(
    private librosService: LibrosService,
    private activeroute: ActivatedRoute,
    public environment: Environment,
    private router: Router,
    private mtsService : MtsService,
    private sanitizer: DomSanitizer,
    private _urlcanonicaservice: UrlcanonicaService,
    public meta: Meta, 
    public title: Title
  ) {
    this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
      
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'http://mts.org.mx/gracias-afiliate'} );
      }else{
        canonical.setAttribute('href', 'http://mts.org.mx/gracias-afiliate');
      }
  
      title.setTitle("Movimiento Trascendental Social");
      meta.updateTag({
        name: "description",
        content: "Si pudiéramos cambiarnos a nosotros mismos, las tendencias en el mundo también podrían cambiar. Tal y como un hombre es capaz de transformar su propia naturaleza, también cambia la actitud del mundo hacia él. "
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 

      this.librosService.getLibros().subscribe((respLibros: any) => {
      this.libros = respLibros; 
      respLibros.reverse() 
      respLibros.forEach((value, index) => {
        if(index <= 5){
            this.mtsService.votos(value.idTipo).subscribe((res: any)=> {
                value.rating = Math.round(res * 10) / 10;;
            })
        } 
      });
    });

   }

   newstr: any;

  ngOnInit(): void {
  }

  getLibroUrl(libro: any) {
    this.newstr = libro.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos = function(cadena) {
      let chars = {
        "á": "a",  ":": "",  ";": "", "é": "e", "í": "i", "ó": "o", "ú": "u", "!": "", "à": "a",
        "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n", ".": "", "¡": "", "Á": "A", "É": "E", "Í": "I",
        "Ó": "O", "Ú": "U", ",": "", "¿": "", "?": "", "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"}
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(["/libro/" + libro.id + "/" + this.newstr]);
  }

}
