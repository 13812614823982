<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Técnicas de meditación</h1>
                <p class="titulo-descripcion">
                    Estudios demuestran que meditar aumenta las emociones positivas y el nivel de satisfacción con
                    nuestra vida, a la vez que disminuye el estrés, la ansiedad y la depresión.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container my-5 ">
    <div class="row pt-5">
        <div class="col-md-5 pt-4 text-center">
            <img src="https://mts.org.mx/assets/images/buda.jpg" width="100%">
        </div>
        <div class="col-md-7">
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <h1 class="titulo-xl mb-3">¿Por qué meditar?<br>
                ¿Cómo Puedo Aprender a Meditar? </h1>
            <p>
                Durante miles de años la gente ha utilizado la meditación para ir más allá de los pensamientos
                estresantes de la mente y los trastornos emocionales, hacia la paz y la claridad de la conciencia del
                momento presente. La variedad de técnicas de meditación, las tradiciones y las tecnologías son casi
                infinitas, pero la esencia de la meditación es una sola: el desarrollo de la conciencia y la conciencia
                expandida.<br><br>
                Estos son los regalos más preciados de la meditación, sin embargo, las personas se sienten atraídas
                inicialmente a la meditación por muchas razones diferentes. Algunas personas empiezan a meditar por
                alguna recomendación médica, buscando obtener beneficios de salud relacionados con su presión arterial,
                reducción de estrés o para lograr tener un sueño reparador. Otros se acercan a la meditación buscando
                alivio a pensamientos dolorosos, de miedo o de enojo que se encuentran constantemente en su mente. Y
                otros se acercan a la meditación para lograr una mayor comprensión de si mismos o para mejorar su
                habilidad de concentración.<br><br>
                Es preciso decir que el propósito de la meditación depende del meditador, pero también es cierto que
                cualquier persona que medita regularmente recibe beneficios profundos en todos estos niveles, físico,
                mental, emocional y espiritual.<br><br>
                Un importante estudio realizado por el Hospital General de Massachusetts encontró que tan sólo ocho
                semanas de meditación no sólo ayudaron a que la gente se sienta más tranquila, si no que también se
                produjeron cambios en varias áreas del cerebro, incluyendo el crecimiento en las áreas asociadas con la
                memoria, la empatía, el sentido de sí mismo y la regulación del estrés.
            </p>
        </div>
        <div class="col-md-10 offset-md-1 mt-5 pt-5 text-center">
            <img class="flor" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo-xl">
                Tipos de meditación
            </p>
            <div class="pleca-center"></div>
            <p>Ya sea para calmar la mente, para encontrarse a uno mismo o para reducir la ansiedad o el estrés, es
                interesante que introduzcas esta práctica en tu vida diaria, pues es un modo de mejorar la calidad de
                vida. Pero… ¿qué tipos de meditación existen? ¿qué nos aportan las distintas maneras de meditar? Puedes
                saber más sobre las diferentes clases de meditación en las siguientes líneas:</p>

        </div>
        
        <div class="col-md-3 " *ngFor="let tecnica of tecnicas">
            <a  class="card-tecnica-meditacion" (click)="getTecnicaUrl(tecnica)" style="cursor:pointer">
                <img class="tecnica-icono" src="assets/images/meditacion/{{tecnica?.icono}}">
                <p class="tecnica-nombre"> {{ tecnica?.titulo}} </p>
                <p class="tecnica-btn">Ver más</p>
            </a>
        </div>
    </div>
</div>
<section class="meditacion-beneficios mb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 offset-lg-5">
                <div class="card-beneficios-meditar">
                    <img class="flor mb-3" src="https://mts.org.mx/assets/images/minimandala.svg">
                    <h2 class="titulo mb-3">Estos son sólo algunos de los beneficios:</h2>
                    <ul>
                        <li>Alivio del estrés y la ansiedad (la meditación mitiga los efectos de la respuesta de
                            «lucha o huida» la disminución de la producción de las hormonas del estrés, como el
                            cortisol y la adrenalina)
                        </li>
                        <li>Disminución de la presión arterial e hipertensión</li>
                        <li>Disminución en los niveles de colesterol</li>
                        <li>Obtenemos un uso más eficiente del oxígeno que es utilizado por nuestro cuerpo</li>
                        <li>Aumento de la producción de la hormona DHEA anti-envejecimiento</li>
                        <li>Un sueño reparador</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container pb-5 mb-5 pt-5">
    <div class="row">
        <div class="col-md-4">
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-meditacion.svg" width="60">
            <p class="titulo">
                Meditación guiada gratuita
            </p>
            <p>
                Isha Kriya es una meditación simple y gratuita diseñada por Sadhguru<br><br>
                Meditar no siempre es fácil, especialmente al inicio, pues requiere disciplina y práctica para su
                perfección. Por tanto, requiere esfuerzo, pero a la larga sus beneficios para la salud son numerosos.
                <br><br>
                En el mundo acelerado en el que vivimos, muchas personas encuentran en la meditación una forma de
                conectar de nuevo con ellos mismos, lejos de la cultura occidental, donde el consumismo y los valores
                materialistas rigen la vida de la gran mayoría de ciudadanos. La meditación es una manera de recuperar
                la esencia de uno mismo y lograr la paz interior. </p>

        </div>
        <div class="col-md-8">

            <iframe class="youtube-video" src="https://www.youtube.com/embed/CtRZYDT3DXg" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>

        </div>
    </div>
</div>