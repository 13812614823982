<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                <h1 class="titulo-pagina">Bienvenid@</h1>               
            </div>
        </div>
    </div>
</section>
<div class="container gracias">
    <div class="row">
        <div class="col-md-6 offset-md-3 pt-5 pb-5 text-center">
            <img src="assets/images/success-icon.png" width="80" class="mb-3">
            <p>A partir de pequeñas acciones puedes contribuir a alinear tu corazón, mente, cuerpo y alma para conseguir una mejor versión de ti.</p>
            <p>Hoy has dado un paso más <strong>afiliándote</strong> a este movimiento social inclusivo que busca generar consciencia, bienestar y unidad entre las personas.</p>
            <p>Pronto te haremos llegar todos los eventos en los que puedes participar </p>
            <h3>¡Mantente pendiente!</h3>
        </div>
    </div>
</div>
<section class="libros-sugeridos">
    <div class="container pb-5 pt-5">
    <div class="row">
        <div class="col-lg-4">
            <img class="flor mb-3" src="http://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo">
                Libros que cambiarán tu vida
            </p>
            <p>¿Alguna vez has leído un libro que te impactó tanto como para cambiar tu vida? ¿Uno que ha hecho que
                desde entonces todo sea diferente?<br><br>
                Puede sonar exagerado, pero lo cierto es que existen algunos libros que por su alto valor y utilidad
                para los lectores, ayudan de verdad a ver el mundo de forma diferente.</p>
            <a routerLink="/libros" class="btn btn-uno mb-5"> Más libros</a>
        </div>
        <!-- LIBROS SUGERIDOS -->
        <div  *ngFor="let libro of libros.slice(0, 4);" class="col-lg-2 col-sm-3 col-6">
            <a class="libro-sugerido" (click)="getLibroUrl(libro)" style="cursor:pointer">
                <img class="libro1" src="http://mts.org.mx/assets/images/libros/{{ libro?.thumbnail }}">
                <p class="nombre-libro1">{{ libro.titulo }}</p>
                <img class="rating" src="http://mts.org.mx/assets/images/rating/{{ libro?.rating }}.svg">
            </a>
        </div>
        <!--FIN LIBROS SUGERIDOS -->
    </div>
</div>
</section>

