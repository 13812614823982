import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from "@angular/core";
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";

import { registerLocaleData } from '@angular/common'
import localeMx from '@angular/common/locales/es-MX'
registerLocaleData(localeMx, 'mx')

import { defineLocale } from 'ngx-bootstrap/chronos'
import { esLocale } from 'ngx-bootstrap/locale'
defineLocale('es', esLocale)



import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { InicioComponent } from "./pages/inicio/inicio.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LibrosComponent } from "./pages/libros/libros.component";
import { LibroComponent } from "./pages/libro/libro.component";
import { GurusComponent } from "./pages/gurus/gurus.component";
import { GuruComponent } from "./pages/guru/guru.component";
import { PeliculasComponent } from "./pages/peliculas/peliculas.component";
import { PeliculaComponent } from "./pages/pelicula/pelicula.component";
import { TecnicasDeMeditacionComponent } from "./pages/tecnicas-de-meditacion/tecnicas-de-meditacion.component";
import { MeditacionComponent } from "./pages/meditacion/meditacion.component";
import { DestinosMagicosComponent } from "./pages/destinos-magicos/destinos-magicos.component";
import { VideosComponent } from "./pages/videos/videos.component";
import { Environment } from './Globals';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClientModule } from "@angular/common/http";
import { RatingModule } from 'ngx-bootstrap/rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DestinoComponent } from './pages/destino/destino.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { AfiliateComponent } from './pages/afiliate/afiliate.component';
import { FrasesComponent } from './pages/frases/frases.component';
import { MeditacionesGuiadasComponent } from './pages/meditaciones-guiadas/meditaciones-guiadas.component';
import { NotasComponent } from './pages/notas/notas.component';
import { NotaComponent } from './pages/nota/nota.component';
import { AvisoLegalComponent } from './pages/aviso-legal/aviso-legal.component';
import { EventoComponent } from './pages/evento/evento.component';
import { AvisoPrivacidadComponent } from './pages/aviso-privacidad/aviso-privacidad.component';
import { GraciasContactoComponent } from './pages/gracias-contacto/gracias-contacto.component';
import { GraciasAfiliateComponent } from './pages/gracias-afiliate/gracias-afiliate.component';
import { GraciasEventoComponent } from './pages/gracias-evento/gracias-evento.component';



@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    HeaderComponent,
    FooterComponent,
    LibrosComponent,
    LibroComponent,
    GurusComponent,
    GuruComponent,
    PeliculasComponent,
    PeliculaComponent,
    TecnicasDeMeditacionComponent,
    MeditacionComponent,
    DestinosMagicosComponent,
    VideosComponent,
    DestinoComponent,
    ContactoComponent,
    AfiliateComponent,
    FrasesComponent,
    MeditacionesGuiadasComponent,
    NotasComponent,
    NotaComponent,
    AvisoLegalComponent,
    EventoComponent,
    AvisoPrivacidadComponent,
    GraciasContactoComponent,
    GraciasAfiliateComponent,
    GraciasEventoComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    NgxIntlTelInputModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    RatingModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule

  ],
  providers: [Environment,{ provide: LOCALE_ID, useValue: 'mx' },BsModalService],
  bootstrap: [AppComponent]
})
export class AppModule {}
