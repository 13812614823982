import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class VideohomeService {

  url='assets/data/videosHome.json'; 

  constructor(private http: HttpClient) {}

  getVideosHome() {
    return this.http.get(this.url);
  }


}
