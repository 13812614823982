<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Técnicas de meditación</h1>
                <p class="titulo-descripcion">
                    Estudios demuestran que meditar aumenta las emociones positivas y el nivel de satisfacción con
                    nuestra vida, a la vez que disminuye el estrés, la ansiedad y la depresión.
                </p>
            </div>
        </div>
    </div>
</section>

<div class="container pb-5 pt-5">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-12 text-center mb-3">
                    <img class="flor" src="assets/images/meditacion/{{tecnica?.icono}}" width="90">
                    <p class="titulo-xl">
                        {{ tecnica?.titulo }}
                    </p>
                    <div class="pleca-center"></div>
                    <div  [innerHTML]="tecnica?.descripcion" class="mt-3 text-justify" > </div>
                </div>
                <div class="col-lg-6">
                    <div class="imagen-adaptable"
                        style="background: url(https://mts.org.mx/assets/images/meditacion/{{tecnica?.imagenUno}});">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div  [innerHTML]="tecnica?.contenidoUno" class="mt-3 text-justify" > </div>
                </div>
                <div class="col-12 mt-5">
                    <div  [innerHTML]="tecnica?.contenidoDos" > </div>
                </div>
                <div class="col-lg-6">
                    <div class="imagen-adaptable"
                        style="background: url(https://mts.org.mx/assets/images/meditacion/{{tecnica?.imagenDos}});">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div  [innerHTML]="tecnica?.contenidoTres" class="mt-3 text-justify" > </div>
                </div>
                <div class="col-12 mt-3">
                    <div  [innerHTML]="tecnica?.contenidoCuatro" > </div>
                </div>
                
            </div>
        </div>
        <!--     sideBar       -->
        <div class="col-lg-3 otras-tecnicas">
            <strong>Otras Técnicas de meditación</strong>
            <ul class="lista">
                <li *ngFor="let tecnica of tecnicas.slice(0, 6);">
                    <a (click)="getTecnicaUrl(tecnica)" style="cursor:pointer">
                        {{ tecnica?.titulo }}
                    </a>
                </li>
            </ul>
        </div>
        <!--     sideBar       -->
    </div>
</div>