import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MtsService } from "../../services/mts.service";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment } from 'src/app/Globals';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  Nombre: string
  Correo: string
  Mensaje: string
  Estado: string
  path: string;
  msjenvio: boolean = false;
  msjexiste: boolean = false;
  msjerro: boolean = false;
  msjsucces:boolean = false;

  constructor(
    private toastr: ToastrService,
    private mtsService : MtsService,
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private activeroute: ActivatedRoute,
    public environment: Environment,
    public meta: Meta, 
    public title: Title
    ) {
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Contacto │ MTS");
      meta.updateTag({
        name: "description",
        content: "Contáctanos, queremos saber de ti"
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      });  
      this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
    }


  ngOnInit(): void {
  }

  sendContacto(f: NgForm){
    this.mtsService.postContacto(f.value).subscribe((resp: any) => {
        if(resp.ok == false){
          this.msjexiste = true;
          this.msjenvio = false;
          setTimeout(()=>{                           
            this.msjexiste = false;
          }, 5000);
        }else{
          this.msjenvio = false;
          setTimeout(()=>{                           
              f.reset();
              this.router.navigate(['gracias-contacto/']);
          }, 5000);
        }
      },
      err => {
        f.reset();
        this.msjerro = true;
        this.msjenvio = false;
        setTimeout(()=>{                           
          this.msjerro = false;
        }, 5000);
      }

    );
  }
}
