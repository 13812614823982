import { Component, OnInit } from '@angular/core';
import { TecnicasService } from '../../services/tecnicas.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from '../../Globals';


@Component({
  selector: 'app-meditacion',
  templateUrl:'./meditacion.component.html',
  styleUrls: ['./meditacion.component.css']
})
export class MeditacionComponent implements OnInit {
  tecnica: any
  tecnicas: any[]
  idnotshow: any
  newstr: any
  videoURL: string;
  safeURL: any;
  scrollint: any
  path: string;

  constructor(
    private router: Router,
    private tecnicasService: TecnicasService,
    private activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private activeroute: ActivatedRoute,
    private _urlcanonicaservice: UrlcanonicaService,
    public environment: Environment,
    public meta: Meta, 
    public title: Title

  ) { 
    this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
    this.scrollint = 0;
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Técnicas de meditación │ MTS");
      meta.updateTag({
        name: "description",
        content: "Estudios demuestran que meditar aumenta las emociones positivas y el nivel de satisfacción con nuestra vida, a la vez que disminuye el estrés, la ansiedad y la depresión."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
      this.activatedRoute.params.subscribe(param => {
        this.tecnicasService.getTecnicas().subscribe((resp: any) =>{
          this.idnotshow = param.id
          this.tecnica = resp.find((tecnica: any) => tecnica.id === parseInt(param.id))
          this.scrollint = 0;
        } )
        this.tecnicasService.getTecnicas().subscribe((respdos: any) => {
        
          respdos.reverse()
          var removeIndex = respdos.map(function(item) { return item.id; }).indexOf(parseInt(this.idnotshow));
          respdos.splice(removeIndex, 1);
          this.tecnicas = respdos
        })
      })
  }

  ngOnInit() {
    
  }

  getVideoIframe(url) {
    if(this.scrollint === 1){
      
    }else{
      var video, results;
 
      if (url === null) {
          return '';
      }
      results = url.match('[\\?&]v=([^&#]*)');
      video   = (results === null) ? url : results[1];
      this.scrollint = 1;
      return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?loop=1&amp;playlist=' + video);   
      
    }  
  }


  getTecnicaUrl(tecnica: any) {
    this.newstr = tecnica.titulo.replace(/\s+/g, '-').toLowerCase();
    let reemplazarAcentos=function(cadena)
    {
      let chars={
        "á":"a", ":":"",  ";":"", "é":"e", "í":"i", "ó":"o", "ú":"u", "!":"",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n", ".":"", "¡":"",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U", ",":"", "¿":"", "?":"",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
      let expr=/[áàéèíìóòúùñ:;,¿?¡!]/ig;
      let res=cadena.replace(expr,function(e){return chars[e]});
      return res;
    }
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(['/meditacion/' + tecnica.id + '/' + this.newstr]);
    //this.getTecnica(tecnica.id);
  }

}
