import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PeliculasService } from "../../services/peliculas.service";
import { MtsService } from "../../services/mts.service";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal"
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';

@Component({
  selector: 'app-peliculas',
  templateUrl: './peliculas.component.html',
  styleUrls: ['./peliculas.component.css']
})
export class PeliculasComponent implements OnInit {
  modalRef: BsModalRef;
  peliculas = [];
  Nombre: string
  Correo: string
  Comentario: string
  NombrePelicula: string
  Director: string
  ComentarioRecomendacion: string
  path: string;
  @ViewChild('lgModal') template: ModalDirective



  constructor( 
    private peliculasService: PeliculasService,
    private router: Router,
    private toastr: ToastrService,
    private mtsService : MtsService,
    private activatedRoute: ActivatedRoute,
    private _urlcanonicaservice: UrlcanonicaService,
    public environment: Environment,
    public meta: Meta, 
    public title: Title
    ) {
      this.path = this.activatedRoute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Películas para reflexionar │ MTS");
      meta.updateTag({
        name: "description",
        content: "Hay películas que duran hora y media, y otras que duran toda una vida."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
  
    this.peliculasService.getPeliculas().subscribe((resp: any) => {
      resp.forEach((value, index) => {
        this.mtsService.votos(value.idTipo).subscribe((res: any)=> {
            value.rating = Math.round(res * 10) / 10;;
        })
      })
      resp.reverse()
      this.peliculas = resp;  

    });
  }
  newstr: any;
  
  ngOnInit() {
  }
 
  
  closeModal() {
    this.template.hide()
  }
  
  openModal() {
    this.template.show()
  }

  sendRecomendacion(f: NgForm){
    this.mtsService.postRecomendacionPelicula(f.value).subscribe(
        resp => {
            this.closeModal();
            f.reset();
            this.toastr.success('Tú recomendacion se envio correctamente');
            //aqui ya realizo comentario ¿ Que haremos ?
        },
        err => {
        console.log(err)
        }
    );
  }

  getPeliculaUrl(pelicula: any) {
    this.newstr = pelicula.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos = function(cadena) {
      let chars = {
          "á": "a",  ":": "",  ";": "", "é": "e", "í": "i", "ó": "o", "ú": "u", "!": "", "à": "a",
          "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n", ".": "", "¡": "", "Á": "A", "É": "E", "Í": "I",
          "Ó": "O", "Ú": "U", ",": "", "¿": "", "?": "", "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"}
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/ig;
      let res = cadena.replace(expr, function(e) {
          return chars[e]
      });
      return res;
    }
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(["/pelicula/" + pelicula.id + "/" + this.newstr]);
  }

}
